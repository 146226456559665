import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import './Buttons.scss';

const Buttons = ({
  buttons, theme, styled = true, colorInvert = false,
}) => (
  <div className="buttons">
    {buttons.map((button) => {
      const {
        buttonLabel,
        buttonLink,
        buttonChildren,
      } = button;
      const { slug, url } = buttonLink;

      if (!buttonLink) return null;

      // Prioritize slug, and handle special case for 'home'
      let to = null;
      if (slug) {
        to = slug === 'home' ? '/' : `/${slug}`;
      } else if (url) {
        to = url;
      }

      // Regex to check if the URL is absolute
      const isAbsolute = new RegExp('^(//|[a-z]+:)', 'i');

      const buttonClass = cn('button', styled && `styled-button${theme ? ` styled-button-${theme}` : ''}`, colorInvert && 'color-invert');

      if (to !== null && !isAbsolute.test(to)) {
        // Internal link with Gatsby's Link component
        return (
          <Link
            key={to}
            to={to}
            className={buttonClass}
            aria-label={`Navigate to ${buttonLabel.text}`}
          >
            {buttonChildren || (
              <span>{buttonLabel.text}</span>
            )}
          </Link>
        );
      } if (url) {
        // External link using <a> element
        return (
          <a
            key={buttonLink.url}
            href={buttonLink.url}
            className={buttonClass}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Navigate to ${buttonLabel.text}`}
          >
            {buttonChildren || (
              <span>{buttonLabel.text}</span>
            )}
          </a>
        );
      }

      return null;
    })}
  </div>
);

export default Buttons;
