import React from 'react';
import {
  Image,
  MixedLink,
  Wrapper,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './IconsBanner.scss';

const IconsBanner = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: icons,
  } = data;
  const {
    theme,
    title,
  } = primary;

  console.log({ icons });

  const themeClass = convertColorToTheme(theme.toLowerCase());

  return (
    <section className={`icons-banner theme-${themeClass}`}>
      <Wrapper isBound>
        <div className="container">
          <div className="text-container">
            <h2 className="title text-h2">{title.text}</h2>
          </div>
          <div className="icons-container">
            {icons.map((icon, index) => (
              <MixedLink
                key={index}
                link={icon.iconLink}
                linkLabel={icon.iconLabel}
                className="icon"
              >
                <div className="icon-container">
                  <Image className="icon" image={icon.iconImage} />
                </div>
              </MixedLink>
            ))}
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default IconsBanner;
