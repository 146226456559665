import React from 'react';
import {
  Wrapper, HtmlContent,
  Buttons,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './TextBanner.scss';

const TextBanner = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    theme,
    title,
    body,
  } = primary;

  const themeClass = convertColorToTheme(theme.toLowerCase());

  return (
    <section className={`text-banner theme-${themeClass}`}>
      <Wrapper isBound>
        <div className="container">
          <div className="text-container">
            <h2 className="title text-h2">{title.text}</h2>
            <HtmlContent
              className="body-content"
              content={body.html}
            />
            {buttons && buttons.length > 0 && (
              <Buttons
                className="buttons"
                buttons={buttons}
                theme={themeClass}
                styled
                colorInvert
              />
            )}
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default TextBanner;
