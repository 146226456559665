import React from 'react';
import {
  Wrapper, Image, HtmlContent, Buttons,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './BannerHero.scss';

const BannerHero = (props) => {
  const {
    data,
  } = props;
  const {
    primary,
    items: buttons,
  } = data;
  const {
    theme,
    title,
    image,
    body,
  } = primary;

  const themeClass = convertColorToTheme(theme.toLowerCase());

  return (
    <section className={`banner-hero theme-${themeClass}`}>
      <Wrapper isBound>
        <div className="container">
          <div className="text-container">
            <h1 className="title">{title.text}</h1>
            <HtmlContent
              className="body-content"
              content={body.html}
            />
            {buttons && buttons.length > 0 && (
              <Buttons
                className="buttons"
                buttons={buttons}
                theme={themeClass}
                styled
                colorInvert
              />
            )}
          </div>
          <div className="image-container">
            <Image image={image} />
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export default BannerHero;
